import Piano from './Piano/App.svelte';

window.piano = new Piano({
	target: document.getElementById('piano'),
});		


// listen to document input focus and close piano
document.addEventListener('focusin', function(e) {
	if( !$(e.target).closest('#piano').length ) window.piano.close();
});
