<svelte:options accessors={true} />


<script>
	import Key from './lib/Key.svelte';
	import { notes, playing, layouts, layout, keyboard, playByKey, stopByKey } from './stores.js';

	// play a note on keydown
	const keydown = function(e) {
		// not on repeat
		if( e.repeat ) return;

		// play note
		playByKey(e.key);
	}

	// release key
	const keyup = function(e) {
		stopByKey(e.key);
	}


	// dispatch "close-piano" event
	const dispatchClose = function() {
		dispatch('close');
	}

	// open piano
	let opened = false;

	// input element passed when opening
	let input;

	// open function
	export const open = function( selector ) {

		// add event listener
		window.addEventListener('keydown', keydown);
		window.addEventListener('keyup', keyup);

		// show container
		opened = true;

		// get input element
		input = document.querySelector( selector );
	}

	// close function
	export const close = function() {

		// remove event listeners
		window.removeEventListener('keydown', keydown);
		window.removeEventListener('keyup', keyup);

		// hide container
		opened = false;
	}

	// toggle piano
	export const toggle = function( selector ) {

		if( opened ) {
			close();
		} else {
			open( selector );
		}
	}


	// when note is playing, update input
	playing.subscribe( id => {
		if( notes[id] ) {

			// get actual input value
			let value = input.value;

			// if value is empty, add note
			if( value.length ) {
				input.value += ' ';
			}
			input.value += notes[id];
		}
	});
</script>


<style>
.keys {
/*	display: block;*/
	width: 100%;
	height: 350px;
/*	max-width: 880px;*/
	position: relative;
/*	margin: auto;*/
}

.container {
	max-width: 880px;
	margin: auto;	
}

</style>

<div class="container {opened ? 'd-block' : 'd-none'} pb-4">
	<div class="row justify-content-between mb-3">
		<div class="col-auto">
			<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
				<button type="button" class="btn btn-secondary btn-sm" disabled><i class="far fa-keyboard"></i></button>
				
				{#each layouts as l }
					<input type="radio" class="btn-check" name="layout" value="{l.name}" id="btn{l.name}" autocomplete="off" bind:group={$layout}/>
					<label class="btn btn-light btn-sm" for="btn{l.name}">{l.name}</label>
				{/each}
			</div>
		</div>
		<div class="col-auto">
			<!-- close button -->
			<button type="button" class="btn btn-secondary btn-sm" on:click={close}><i class="fas fa-times"></i></button>
		</div>

	</div>

	<div class="keys">
		{#each $keyboard as key }
			<Key {...key} />
		{/each}
	</div>
</div>
