<svelte:options accessors={true} />

<script>
	// import playing from store
	import { playing, play, stop } from '../stores.js';

	export let id; // note number
	export let key; // letter
	export let sharp = false;
	export let note;
	export let style = "";

	// key is down ?
	$: keydown = ($playing == id);
</script>



<div class="key {sharp?'sharp':''} {keydown?'keydown':''}" {style} on:mousedown={play(id)} on:mouseup={stop(id)} on:mouseleave={stop(id)}>
    <span class="hint">
    	<small class="hover">{ key.toLowerCase() }</small><br/>
    	{ note }
    </span>
</div>



<style type="text/scss">
.key {
	position: relative;
	border: 2px solid black;
	border-radius: .5rem;
	transition: all .07s ease;
	display: block;
	box-sizing: border-box;
	z-index: 2;
	cursor: pointer;
	box-shadow: 0 0 10px rgba(0,0,0,.5);

	&:not(.sharp) {
		float: left;
		width: 10%;
		height: 100%;
		background: #fff;    

		&:hover {
			background-image: linear-gradient(#fff, #eee);
		}

		/* keydown */
		&.keydown {
			background-image: linear-gradient(#fff, #ccc);
		}
	}

	&.sharp {
		position: absolute;
		width: 6%;
		height: 60%;
		background: #000;
		color: #eee;
		top: 0;
		z-index: 3;

		&:hover {
			background-image: linear-gradient(#000, #222);
		}

		/* keydown */
		&.keydown {
			background-image: linear-gradient(#000, #444);
		}
	}
}

.hint {
	width: 100%;
	position: absolute;
	bottom: 7px;
	transition: opacity .3s ease-out;
	font-size: 20px;
	text-align: center;
	user-select: none;
}

.hover {
	display: none;
}
.key:hover .hover {
	display: inline-block;
}

</style>