import { createEventDispatcher } from 'svelte';
import { writable, derived, get } from 'svelte/store';
import { persistent } from "@furudean/svelte-persistent-store";



// all notes
export const notes = [ 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E' ];

// all audios
export const audios = notes.map( ( key, id ) => {
	return new Audio( '/js/Piano/sounds/'+ (id+40).toString().padStart(3,'0') + '.wav' );
});


// current playing note (id)
export const playing = writable(-1);



// play a note, given its id
export const play = function( id ) {

	stop( get(playing) );

	if( audios[id] ) {
		audios[id].currentTime = 0;
		audios[id].play();
		
		playing.set(id);
	}
}


// stop a note, given its id
export const stop = function( id ) {
	if( audios[id] ) {
		// audios[id].pause();
		playing.set(-1);
	}
}


// keyboard layouts
export const layouts = [
	{ name: "AZERTY", keys: [ 'Q','Z','S','E','D','F','T','G','Y','H','U','J','K','O','L','P','M' ] },
	{ name: "QWERTY", keys: [ 'A','W','S','E','D','F','T','G','Y','H','U','J','K','O','L','P',';' ] },
	{ name: "QWERTZ", keys: [ 'A','W','S','E','D','F','T','G','Z','H','U','J','K','O','L','P','Ö' ] },

];


// load layout from local storage
export const layout = persistent({
	key: "piano-layout",
	storage_type: 'localStorage',
	start_value: "AZERTY"
});



// try to detect layout map from navigator
try {
	navigator.keyboard.getLayoutMap().then((keyboardLayoutMap) => {
		const test = keyboardLayoutMap.get("KeyQ") + keyboardLayoutMap.get("KeyY");
		switch( test ) 
		{
			case "qy": layout.set("QWERTY"); break;
			case "ay": layout.set("AZERTY"); break;
			case "qz": layout.set("QWERTZ"); break;
		}
	});
}
catch( e ) {
	console.error(e);
}



// define keymap according to layout
export const keymap = derived( layout, function( value ) {
	for( let i=0; i<layouts.length; i++ ) {
		if( layouts[i].name == value ) {
			return layouts[i].keys;
		}
	}
	
	throw new Error("Invalid layout");
});



// build keyboard configuration
export const keyboard = derived( keymap, function( keys ) {

	// count white keys
	let white = 0;

	// foreach keys of given keymap
	return keys.map( (key, id) => {

		const value = {
			id: id,
			key: key,
			note: notes[id],
			sharp: notes[id].slice(-1) == "#",
		}

		if( !value.sharp ) white++;
		else value.style = "left:"+((white-1)*10+7)+"%";

		return value;
	});
});


// play by key
export const playByKey = function( key ) {
	const id = get(keymap).indexOf(key.toUpperCase());
	if( id >= 0 ) play(id);
}

// stop by key
export const stopByKey = function( key ) {
	const id = get(keymap).indexOf(key.toUpperCase());
	if( id >= 0 ) stop(id);
}

